import {
  Button,
  TableContainer,
  Typography,
  TableCell,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import certificatesService from "./services/certificates.service";
import { useParams, Link } from "react-router-dom";
import { getCompanyById } from "./services/companies.service";

export default function CertsByCompany(props) {
  const [allCertsLoaded, setAllCertsLoaded] = React.useState(false);
  const { companyId } = useParams();
  const [companyName, setCompanyName] = React.useState(null);

  const getCompany = async () => {
    const response = await getCompanyById(companyId);
    setCompanyName(response.data.name);
  };
  const handleClick = async (event) => {
    try {
      if (!allCertsLoaded) {
        const nextPage = page + 1;
        setPage(nextPage);
        const response = await certificatesService.getCertsByCompanyId(
          companyId,
          nextPage,
          pageSize
        );
        const prevData = data;
        const nextData = prevData.concat(response.data.certificates);
        if (nextData.length === prevData.length) {
          setAllCertsLoaded(true);
        }
        setData(nextData);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [data, setData] = React.useState(["none"]);

  const initialLoad = async (page, pageSize) => {
    try {
      const response = await certificatesService.getCertsByCompanyId(
        companyId,
        page,
        pageSize
      );
      setData(response.data.certificates);
      return response.data.certificates;
    } catch (e) {
      console.error(e);
    }
  };
  React.useEffect(() => {
    initialLoad(page, pageSize);
    getCompany();
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <Typography sx={{ paddingTop: "50px", paddingBottom: "50px" }}>
        Certificates from {companyName}
      </Typography>
      <TableContainer
        sx={{ maxWidth: 900, margin: "0 auto" }}
        component={Paper}
      >
        <Table
          sx={{
            minWidth: 650,
            maxWidth: 900,
            margin: "0 auto",
            paddingTop: "20px",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{}}>Certificate ID</TableCell>
              <TableCell align="left">Date issued</TableCell>
              <TableCell align="left">Credential</TableCell>
              <TableCell align="left">Recipient Name</TableCell>
            </TableRow>
          </TableHead>
          {data.map((item, index) => {
            return (
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link to={`/certificates/${item.id}`}>{item.id}</Link>
                  </TableCell>
                  <TableCell align="left">{item.date_issued}</TableCell>
                  <TableCell align="left">{item.credential_type}</TableCell>
                  <TableCell align="left">{item.recipient_name}</TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
      {!allCertsLoaded && (
        <Button sx={{ paddingTop: "20px" }} onClick={handleClick}>
          Click to show more
        </Button>
      )}
    </div>
  );
}
