import axios from "axios";

const ax = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export default ax;
