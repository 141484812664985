import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  TextField,
  Button,
  FormControl,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import hirebrainLogo from "./media/logo.svg";
import certificatesService from "./services/certificates.service";
import { getCompanyById } from "./services/companies.service";
import { signOut } from "./services/users.service";
import { Navigate, useNavigate } from "react-router-dom";

const lightColor = "rgba(255, 255, 255, 0.7)";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

export default function CertFromId() {
  const [certId, setCertId] = React.useState(null);
  const handleCertIdChange = (event) => {
    setCertId(event.target.value);
  };

  const [tableShown, setTableShown] = React.useState(false);

  React.useEffect(() => {
    setTableShown(tableShown);
  }, [tableShown]);
  const navigate = useNavigate();

  const [companyName, setCompanyName] = React.useState(null);
  const [data, setData] = React.useState([]);

  const handleSubmit = async (event) => {
    try {
      const response = await certificatesService.getCertById(certId);
      navigate(`/certificates/${response.data.id}`);
      setData(response.data);
      const company = await getCompanyById(response.data.company_id);
      setCompanyName(company.data.name);
      setTableShown(true);
    } catch (e) {
      console.log(e.response.status);
      if (e.response.status === 404) {
        window.alert("No certificate with this ID was found");
      }
      setTableShown(false);
      console.error(`Error thrown trying to load certificate: ${e}`);
    }
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
          <CssBaseline />
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <AppBar color="primary" position="sticky" elevation={0}>
              <Toolbar>
                <Link href="/">
                  <img
                    alt="HireBrain logo"
                    src={hirebrainLogo}
                    style={{ width: "300px" }}
                  ></img>
                </Link>
                <Grid container spacing={1} alignItems="center">
                  <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs />
                  {localStorage.getItem("token") && (
                    <Link
                      href="/"
                      sx={{
                        textDecoration: "none",
                        color: lightColor,
                        "&:hover": {
                          color: "common.white",
                        },
                      }}
                      onClick={signOut}
                    >
                      Sign out
                    </Link>
                  )}
                </Grid>
              </Toolbar>
            </AppBar>
            <AppBar
              component="div"
              color="primary"
              position="static"
              elevation={0}
              sx={{ zIndex: 0 }}
            >
              <Toolbar>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs>
                    <Typography
                      color="inherit"
                      variant="h5"
                      component="h1"
                      sx={{ paddingBottom: "50px" }}
                    >
                      Find certificate by ID
                    </Typography>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <div style={{ paddingTop: "20px", margin: "0 auto" }}>
              <FormControl sx={{ display: "flex", minWidth: "600px" }}>
                <TextField
                  value={certId}
                  onChange={handleCertIdChange}
                  label="Enter certificate ID"
                  sx={{ paddingRight: "20px", paddingBottom: "20px" }}
                ></TextField>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                {tableShown && (
                  <Table
                    sx={{
                      minWidth: 650,
                      maxWidth: 900,
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                  >
                    <TableHead sx={{ paddingTop: "10px" }}>
                      <TableRow>
                        <TableCell sx={{ width: "130px" }}>
                          Date Issued{" "}
                        </TableCell>
                        <TableCell align="left">Credential Type</TableCell>
                        <TableCell align="left">Recipient Name</TableCell>
                        <TableCell align="left">Company Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {data.date_issued}
                        </TableCell>
                        <TableCell align="left">
                          {data.credential_type}
                        </TableCell>
                        <TableCell align="left">
                          {data.recipient_name}
                        </TableCell>
                        <TableCell align="left">{companyName}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </FormControl>
            </div>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}
