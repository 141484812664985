import * as React from "react";
import {
  Avatar,
  Paper,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import { json } from "react-router-dom";
import { getAllUsers } from "../../services/users.service";
import { useSelector, useDispatch } from "react-redux";

export default function ShowAllUsers(props) {
  // const [users, setUsers] = React.useState(["none", "none"]);
  const { users } = useSelector((state) => state.UserReducer);
  console.log(users);
  const [page, setPage] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(5);
  const [allUsersLoaded, setAllUsersLoaded] = React.useState(false);
  const [isRendered, setIsRendered] = React.useState(props.isRendered);

  const handleClick = async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    const response = await getAllUsers("", nextPage, pageSize);
    return; // setUsers((prevUsers) => prevUsers.concat(response.data.users));
  };

  const loadRoles = (item) => {
    if (item.roles) {
      try {
        return item.roles
          .map((role) => {
            return role.name;
          })
          .join(", ");
      } catch (e) {
        console.error(e);
      }
    }
  };

  React.useEffect(() => {
    setIsRendered(props.isRendered);
    // setUsers(props.users);
    setPageSize(props.pageSize);
    setPage(props.page);
    setAllUsersLoaded(props.allUsersLoaded);
  }, [props.isRendered, props.allUsersLoaded, allUsersLoaded]);

  return (
    <>
      {isRendered && (
        <div style={{ paddingTop: "20px" }}>
          <TableContainer
            sx={{ maxWidth: 900, margin: "0 auto" }}
            component={Paper}
          >
            <Table
              sx={{
                minWidth: 650,
                maxWidth: 900,
                margin: "0 auto",
                paddingTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "130px" }}>User id</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Roles</TableCell>
                </TableRow>
              </TableHead>
              {users.map((item, index) => {
                return (
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.id}
                      </TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="left">{loadRoles(item)}</TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
            </Table>
          </TableContainer>
          {!allUsersLoaded && (
            <Button sx={{ padding: "10px" }} onClick={handleClick}>
              Show more
            </Button>
          )}
        </div>
      )}
    </>
  );
}
