import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MuiFileInput } from "mui-file-input";
import csvExampleCode from "../media/exampleCsv.png";
import csvExampleFile from "../media/example.csv";
import * as FormData from "form-data";
import certificatesService from "../services/certificates.service";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function CSVUpload(props) {
  const navigate = useNavigate();

  const [file, setFile] = React.useState(null);
  const handleFileChange = (event) => {
    //Clearing MuiFileInput's file field if the user presses x
    if (!event) {
      return setFile(event);
    }
    //Ensuring we have the right file type
    else if (event.type !== "text/csv") {
      window.alert("Error: Wrong file type");
      return setFile(null);
    }
    console.log(event);
    const selectedFile = event;
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (file) {
      console.log("File submitted:", file);
      try {
        const response = await certificatesService.bulkUpload(file, company.id);
        //send success message and reload page upon success
        window.alert(response.data.message);
        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    } else {
      console.log("No file selected.");
    }
  };

  const [company, setCompany] = React.useState(null);

  const [companyName, setCompanyName] = React.useState(null);
  const handleCompanyNameChange = (event) => {
    if (event.target.outerText) {
      setCompanyName(event.target.outerText);
      setCompany(
        companies.find((item) => item.name === event.target.outerText)
      );
    } else {
      setCompanyName("");
    }
  };

  const { companies } = useSelector((state) => state.CompanyReducer);

  const [companyNames, setCompanyNames] = React.useState(["none"]);

  React.useEffect(() => {
    if (companies) setCompanyNames(companies.map((company) => company.name));
  }, [companies]);

  return (
    <>
      <Typography sx={{ marginBottom: "20px", paddingTop: "20px" }}>
        Submit a CSV file here.{" "}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          sx={{ maxWidth: "200px", position: "relative", margin: "0 auto" }}
          options={companyNames}
          value={companyName}
          onChange={handleCompanyNameChange}
          disablePortal
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company name"
              sx={{ maxWidth: "200px", margin: "20px" }}
            />
          )}
        />
        <MuiFileInput
          value={file}
          onChange={handleFileChange}
          label="Upload CSV here"
          sx={{ marginRight: "20px", marginBottom: "20px" }}
        />
        <Button variant="contained" type="submit" onClick={handleSubmit}>
          Submit CSV
        </Button>
      </form>
      <Typography>
        {" "}
        The CSV must be formatted in a style like this or there will be errors.
      </Typography>
      <Typography sx={{ marginBottom: "20px" }}>
        Ensure there are no spaces between each item and column.
      </Typography>
      <div style={{ width: "100%" }}>
        <img src={csvExampleCode}></img>
      </div>
      <a
        href={csvExampleFile}
        download="example"
        target="_blank"
        rel="noreferrer"
      >
        <Button>Download an example CSV</Button>
      </a>
    </>
  );
}
