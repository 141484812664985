import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import certified from "../media/certified.png";
import committed from "../media/committed.png";
import advanced from "../media/advanced.png";
import facilitator from "../media/facilitator.png";
import cert_trainer from "../media/cert_trainer.png";
import master_trainer from "../media/master_trainer.png";
import logoPlaceholder from "../media/logo-placeholder.png";
import stripBorder from "../media/strip-border.png";
import FontEBGaramond from "../css/fonts/EBGaramond-Bold.ttf";
import FontLato from "../css/fonts/Lato-Medium.woff";
import FontLatoBold from "../css/fonts/Lato-Bold.ttf";
import Certificate from "../Certificate";
import Signature from "../media/signature.jpg";

// // Register the EB Garamond font
Font.register({
  family: "EB Garamond, serif",
  src: FontEBGaramond,
});
Font.register({
  family: "Lato-Medium",
  src: FontLato,
});
Font.register({
  family: "Lato",
  src: FontLatoBold,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  section: {
    margin: 0,
    padding: 0,
    flexGrow: 1,
  },
  text: {
    fontFamily: "EB Garamond, serif",
    fontSize: "42px",
    textAlign: "left",

    paddingTop: "53px",
    paddingLeft: "48px",
    color: "#fff",
    lineHeight: "100%",
    fontWeight: "bold",
  },
  text1: {
    fontFamily: "EB Garamond, serif",
    fontSize: "57px",
    textAlign: "left",
    display: "block",
    paddingLeft: "48px",
    lineHeight: "100%",
    color: "#fff",
    marginTop: "-15px",
    fontWeight: "bold",
  },
  text2: {
    fontFamily: "Lato-Medium",
    fontSize: 18,
    textAlign: "left",
    display: "block",
    paddingLeft: "54",
    color: "#555",
    fontWeight: "medium",
    lineHeight: "110%",
    paddingTop: "90px",
    marginBottom: "4px",
  },
  text3: {
    fontFamily: "Lato-Medium",
    fontSize: 40,
    textAlign: "left",
    display: "block",
    paddingLeft: "54px",
    color: "#3C3E42",
    fontWeight: "medium",
    lineHeight: "110%",
    maxWidth: "608px",
    marginBottom: "5px",
  },
  text4: {
    fontFamily: "Lato-Medium",
    fontSize: 18,
    textAlign: "left",
    display: "block",
    paddingLeft: "54",
    color: "#555",
    fontWeight: "medium",
    lineHeight: "110%",
    maxWidth: "518px",
    marginBottom: "10px",
  },
  text5: {
    fontFamily: "Lato-Medium",
    fontSize: 16,
    textAlign: "left",
    display: "block",
    paddingLeft: "54",
    color: "#555",
    fontWeight: "medium",
    lineHeight: "130%",
  },
  text6: {
    fontFamily: "Lato",
    fontSize: 16,
    textAlign: "left",
    display: "block",
    paddingLeft: "54",
    color: "#555",
    fontWeight: "bold",
    lineHeight: "130%",
  },
  text7: {
    // fontFamily: "EB Garamond, serif",
    fontSize: 16,
    textAlign: "center",
    display: "block",
    color: "#555",

    fontWeight: "medium",
    lineHeight: "130%",
    textTransform: "uppercase",
    paddingTop: "6px",
  },
  text8: {
    // fontFamily: "EB Garamond, serif",
    fontSize: 12,
    textAlign: "center",
    display: "block",
    color: "#555",
    opacity: 0.5,
    fontWeight: "medium",
    lineHeight: "130%",
    textTransform: "uppercase",
    paddingTop: "6px",
  },
  image: {
    width: "100%",
    height: "100%",
    marginBottom: 0,
    position: "absolute",
    left: "0px",
    top: "0px",
  },
  image1: {
    width: "59px",
    textAlign: "right",
    position: "absolute",
    left: "91%",
    top: "14px",
  },
  image2: {
    width: "154px",
    height: "3px",
  },
  image4: {
    width: "154px",
  },
  dateBox: {
    display: "flex",
    marginLeft: "54px",
    maxWidth: "400px",
    flexWrap: "wrap",
  },
  signatureOuterBox: {
    flexDirection: "row", // Arrange items in a row
    justifyContent: "space-between", // Space between the two columns
    width: "328",
    marginLeft: "54px",
  },
  signatureBox: {
    width: "50%",
    textAlign: "center",
  },
});

generateBasicDetailsPDF.propTypes = {
  data: PropTypes.object,
};

export default function generateBasicDetailsPDF(data, level, apiServerUrl,company) {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <View>
            <Image style={styles.image} src={level.cert} />
            <Image style={styles.image1}
            src={
              company?.logo_path2 &&
              company.logo_path2.replace(
                "./",
                apiServerUrl
              )
            } 
            
            />
            <Text style={styles.text}>Certificate of</Text>
            <Text style={styles.text1}>Completion</Text>
            <Text style={styles.text2}>This is to certify that</Text>
            <Text style={styles.text3}>{data.recipient_name}</Text>
            <Text style={styles.text4}>
              has successfully completed the requisite training and demonstrated
              outstanding commitment and proficiency as a hiring manager
            </Text>
            <Text style={styles.dateBox}>
              <Text style={styles.text5}>
                Issued on: <Text style={styles.text6}>{data.date_issued}</Text>{" "}
                |
              </Text>
              <Text style={styles.text5}>
                Expires on: <Text style={styles.text6}>{data.date_expiry}</Text>
              </Text>
            </Text>
            <Text style={styles.text5}>
              Credential ID: <Text style={styles.text6}>{data.id}</Text>
            </Text>
            <View style={styles.signatureOuterBox}>
              <View style={styles.signatureBox}>
                <Image style={styles.image4} 
                src={
                  data?.signature_1 &&
                  data.signature_1.replace(
                    "./",
                    apiServerUrl
                  )
                } 
                
                />
                <Image style={styles.image2} src={stripBorder} />
                <Text style={styles.text7}>{data.signature_name_1}</Text>
                <Text style={styles.text8}>{data.signature_role_1}</Text>{" "}
              </View>
              <View style={styles.signatureBox}>
                <Image style={styles.image4} 
                src={
                  data?.signature_2 &&
                  data.signature_2.replace(
                    "./",
                    apiServerUrl
                  )
                } 
               
                />
                <Image style={styles.image2} src={stripBorder} />
                <Text style={styles.text7}>{data.signature_name_2}</Text>
                <Text style={styles.text8}>{data.signature_role_2}</Text>{" "}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
