import { userConstants } from "../constants/user.constants";
import { getAllUsers } from "../../services/users.service";

export const getUsers = () => async (dispatch) => {
  const response = await getAllUsers();

  console.log(response, "here");
  dispatch({
    type: userConstants.ALL_USERS,
    payload: response.data.users,
  });
};
