import * as React from "react";
import { AppBar, Tab, Tabs } from "@mui/material";
import { Outlet, Link } from "react-router-dom";
import ShowAllCompanies from "./companyPanel/ShowAllCompanies";
import AddCompany from "./companyPanel/AddCompany";
import EditCompany from "./companyPanel/EditCompany";

function CompanyPanel(props) {
  //showing all companies is our default state
  const [activeTab, setActiveTab] = React.useState("ShowAllCompanies");

  const handleTabChange = (event, newTab) => {
    return setActiveTab(newTab);
  };

  return (
    <>
      {
        <div>
          <AppBar
            component="div"
            position="static"
            elevation={0}
            sx={{ zIndex: 0, background: "#225599" }}
          >
            <Tabs
              textColor="inherit"
              value={activeTab}
              onChange={handleTabChange}
            >
              <Tab label="Show all companies" value="ShowAllCompanies" />
              <Tab label="Add a company" value="AddCompany" />
              <Tab label="Modify a company" value="ModifyCompany" />
            </Tabs>
          </AppBar>
          <ShowAllCompanies isRendered={activeTab === "ShowAllCompanies"} />

          <AddCompany isRendered={activeTab === "AddCompany"} />

          <EditCompany isRendered={activeTab === "ModifyCompany"} />
        </div>
      }
    </>
  );
}

export default CompanyPanel;
