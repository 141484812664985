import { companyConstants } from "../constants/company.constants";
import { getAllCompanies } from "../../services/companies.service";

export const getCompanies = () => async (dispatch) => {
  const response = await getAllCompanies();
  console.log(response);
  dispatch({
    type: companyConstants.ALL_COMPANIES,
    payload: response.data.companies,
  });
};
