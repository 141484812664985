import {
  Typography,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  FormGroup,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import * as React from "react";
import { Navigate } from "react-router-dom";
// import usersService from "../../services/users.service";
import { editUser as editUserService } from "../../services/users.service";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/actions/userActions";

export default function EditUser(props) {
  const dispatch = useDispatch();
  const [user, setUser] = React.useState(null);
  const [isRendered, setIsRendered] = React.useState(props.isRendered);
  // const [users, setUsers] = React.useState(null);
  const { users } = useSelector((state) => state.UserReducer);
  const [userEmails, setUserEmails] = React.useState(["none"]);

  React.useEffect(() => {
    try {
      // setUsers(props.users);
      setIsRendered(props.isRendered);
      if (users) setUserEmails(users.map((item) => item.email));
    } catch (e) {
      console.error(e);
    }
  }, [props.isRendered, users]);

  const [oldEmail, setOldEmail] = React.useState(null);

  const handleOldEmailChange = (event) => {
    if (event.target.outerText) {
      setOldEmail(event.target.outerText);
      //here we set the user based on the name
      setUser(users.find((item) => item.email === event.target.outerText));
    } else {
      setOldEmail("");
    }
  };

  const [newUserName, setNewUserName] = React.useState(null);
  const handleNewUserNameChange = (event) => {
    setNewUserName(event.target.value);
  };
  const [newEmail, setNewEmail] = React.useState(null);
  const handleNewEmailChange = (event) => {
    setNewEmail(event.target.value);
  };

  const [newPassword, setNewPassword] = React.useState(null);
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const [newAdminRole, setNewAdminRole] = React.useState(false);
  const handleNewAdminRoleChange = (event) => {
    setNewAdminRole(event.target.checked);
  };

  const [newUserRole, setNewUserRole] = React.useState(false);
  const handleNewUserRoleChange = (event) => {
    setNewUserRole(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!oldEmail) {
      return window.alert("Old user email field cannot be empty");
    }
    if (
      !newUserName &&
      !newEmail &&
      !newPassword &&
      !newAdminRole &&
      !newUserRole
    ) {
      return window.alert("You must enter a field to edit");
    }
    if (newEmail) {
      if (!newEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        return window.alert("You must enter a valid email address");
      }
    }
    if (!newAdminRole && !newUserRole) {
      return window.alert("The user must have roles");
    }
    const roles = [];
    if (newUserRole) roles.push("user");
    if (newAdminRole) roles.push("admin");

    try {
      const response = await editUserService({
        oldEmail: oldEmail,
        name: newUserName,
        email: newEmail,
        password: newPassword,
        roles: roles,
      });
      dispatch(getUsers());

      return window.alert("User edited successfully");
    } catch (e) {
      Navigate("/");
      console.error(e);
      return window.alert(
        "Some error caused the request to not go through: Most likely the email was taken"
      );
    }
  };

  return (
    <>
      {isRendered && (
        <div style={{ minWidth: "900px" }}>
          <Typography sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
            Enter the details for the user you want to edit here
          </Typography>
          <FormControl sx={{ paddingTop: "20px", minWidth: "1200px" }}>
            <div style={{ paddingBottom: "20px" }}>
              <Autocomplete
                sx={{
                  maxWidth: "200px",
                  margin: "0 auto",
                  paddingBottom: "20px",
                }}
                options={userEmails}
                value={oldEmail}
                onChange={handleOldEmailChange}
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Old user email"
                    sx={{ minWidth: "400px", margin: "0 auto", right: "150px" }}
                  />
                )}
              />
              <div
                style={{
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  maxWidth: "900px",
                }}
              >
                <TextField
                  value={newEmail}
                  onChange={handleNewEmailChange}
                  label="New email"
                  sx={{ paddingRight: "20px" }}
                ></TextField>
                <TextField
                  value={newUserName}
                  onChange={handleNewUserNameChange}
                  label="New name"
                  sx={{ paddingRight: "20px" }}
                ></TextField>
                <TextField
                  value={newPassword}
                  onChange={handlePasswordChange}
                  type="password"
                  label="Password"
                  sx={{ paddingRight: "20px" }}
                ></TextField>

                <FormGroup sx={{ maxWidth: "100px" }}>
                  <FormControlLabel
                    sx={{ top: "100px", maxHeight: "48px" }}
                    control={
                      <Checkbox
                        value={newUserRole}
                        onChange={handleNewUserRoleChange}
                      />
                    }
                    label="User"
                  />
                  <FormControlLabel
                    sx={{ maxHeight: "30px" }}
                    control={
                      <Checkbox
                        value={newAdminRole}
                        onChange={handleNewAdminRoleChange}
                      />
                    }
                    label="Admin"
                  />
                </FormGroup>
              </div>
            </div>
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              sx={{
                height: "56px",
                borderTop: "20px",
                maxWidth: "200px",
                margin: "0 auto",
              }}
            >
              Edit user
            </Button>
          </FormControl>
        </div>
      )}
    </>
  );
}
