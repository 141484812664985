import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import hirebrainLogo from "../media/logo.svg";
import { signOut } from "../services/users.service";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header(props) {
  const navigate = useNavigate();
  const { onDrawerToggle, activeTab, setActiveTab } = props;
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    navigate(newTab);
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Link href="/adminpanel/certificates">
            <img
              alt="HireBrain logo"
              src={hirebrainLogo}
              style={{ width: "300px" }}
            ></img>
          </Link>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Link
                // href="/"
                variant="body2"
                sx={{
                  textDecoration: "none",
                  color: lightColor,
                  "&:hover": {
                    color: "common.white",
                  },
                  padding: "20px",
                }}
                rel="noopener noreferrer"
              >
                Go to docs
              </Link>
              <Link
                href="/"
                sx={{
                  textDecoration: "none",
                  color: lightColor,
                  "&:hover": {
                    color: "common.white",
                  },
                }}
                onClick={signOut}
              >
                Sign out
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Certificates Admin Panel
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={activeTab} textColor="inherit" onChange={handleTabChange}>
          <Tab label="Add certificates" value="certificates" />
          <Tab label="Company panel" value="companies" />
          <Tab label="User management" value="users" />
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
