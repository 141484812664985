import { userConstants } from "../constants/user.constants";

const initialState = {
  users: [],
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.ALL_USERS:
      return { ...state, users: action.payload };

    default:
      return state;
  }
};

export default UserReducer;
