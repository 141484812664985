import {
  Avatar,
  Paper,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from "@mui/material";
import * as React from "react";
import hireBrainIcon from "../../media/hirebrain-icon.webp";
import { useNavigate } from "react-router-dom";
import companiesService from "../../services/companies.service";

import { Link } from "react-router-dom";
import usersService from "../../services/users.service";
import { useSelector, useDispatch } from "react-redux";

//TODO: PAGINATION AND MAKE IMAGES WORK

export default function ShowAllCompanies(props) {
  const [isRendered, setIsRendered] = React.useState(props.isRendered);
  // const [companies, setCompanies] = React.useState([]);
  const { companies } = useSelector((state) => state.CompanyReducer);
  const [page, setPage] = React.useState(props.page);
  const [pageSize, setPageSize] = React.useState(props.pageSize);

  const [displayedIndex, setDisplayedIndex] = React.useState(5);

  React.useEffect(() => {
    setIsRendered(props.isRendered);
    // setCompanies(props.companies);
  }, [props.isRendered, props.companies]);

  async function handleClick(e) {
    setDisplayedIndex(displayedIndex + 5);
  }
  function handleShowAllCerts() {
    navigate("/certsbycompany");
  }

  const navigate = useNavigate();
  function getImageUrl(item) {
    return `http://localhost:5000/public${item.logo_path.split("public")[1]}`;
  }
  const [companyId, setCompanyId] = React.useState(null);
  return (
    <>
      {isRendered && (
        <div style={{ paddingTop: "20px" }}>
          <TableContainer
            sx={{ maxWidth: 900, margin: "0 auto" }}
            component={Paper}
          >
            <Table
              sx={{
                minWidth: 650,
                maxWidth: 900,
                margin: "0 auto",
                paddingTop: "20px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "130px" }}>Company Logo</TableCell>
                  <TableCell align="left">Company Name</TableCell>
                  <TableCell align="left">Show More</TableCell>
                </TableRow>
              </TableHead>

              {companies &&
                companies.slice(0, displayedIndex).map((item, index) => {
                  return (
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={index}
                      >
                        <TableCell component="th" scope="row">
                          {/* This absolute path will need to be changed upon deployment*/}
                          <Avatar
                            src={item.logo_path && getImageUrl(item)}
                            variant="square"
                            size="200"
                          ></Avatar>
                        </TableCell>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">
                          <a>
                            <Link
                              to={`/adminPanel/companyCertificates/${item.id}`}
                            >
                              Show all certificates from this company
                            </Link>
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
          {displayedIndex < companies.length && (
            <Button sx={{ padding: "10px" }} onClick={handleClick}>
              Show more
            </Button>
          )}
        </div>
      )}
    </>
  );
}
