import http from "../http-common";
import * as FormData from "form-data";

class CertificateDataService {
  getCertById(id) {
    return http.get(`/certificates?id=${id}`);
  }
  bulkUpload(file, companyId) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/certificates/${companyId}/bulkUpload`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${localStorage.getItem("token")}`,
        },
        data: formData,
      };
      try {
        const response = await http.request(config);
        resolve(response);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
  getCertsByCompanyId(companyId, page, pageSize) {
    return new Promise(async (resolve, reject) => {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `/certificates/${parseInt(
          companyId
        )}?page=${page}&pageSize=${pageSize}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      try {
        const response = await http.request(config);
        resolve(response);
      } catch (e) {
        console.error(e);
      }
    });
  }
}

export default new CertificateDataService();
