import {
  Typography,
  FormControl,
  TextField,
  Button,
  colors,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import * as React from "react";
import { addCompany as addCompanyService } from "../../services/companies.service";
import { useSelector, useDispatch } from "react-redux";
import { getCompanies } from "../../redux/actions/companyActions";

export default function AddCompany(props) {
  const dispatch = useDispatch();
  const [isRendered, setIsRendered] = React.useState(props.isRendered);
  const { companies } = useSelector((state) => state.CompanyReducer);

  React.useEffect(() => {
    setIsRendered(props.isRendered);
  }, [props.isRendered]);

  const [file, setFile] = React.useState(null);

  const handleFileChange = (event) => {
    if (!event) {
      return setFile(event);
    }
    if (!event.type.includes("image")) {
      window.alert(
        "You chose an invalid file type. Please choose a valid image type"
      );
      return setFile(null);
    } else {
      const selectedFile = event;
      setFile(selectedFile);
    }
    console.log(file);
  };
  const [companyName, setCompanyName] = React.useState(null);

  const handleCompanyChange = (event) => {
    setCompanyName(event.target.value);
    if (!event) {
      return setCompanyName(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!companyName) {
      return window.alert("Company name cannot be empty");
    } else if (!file) {
      return window.alert("You must provide a company image");
    }
    companies.forEach((element) => {
      if (element.name.toLowerCase() === companyName.toLowerCase()) {
        return window.alert("Company name is already in use");
      }
    });
    try {
      await addCompanyService(file, companyName);
      dispatch(getCompanies());

      window.alert("Company added successfully");
      //for some reason setting company name to null doesn't actually clear the window
      //we do this nasty getElementById nonsense instead
      return [
        setCompanyName(null),
        setFile(null),
        (document.getElementById("companyField").value = ""),
      ];
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isRendered && (
        <>
          <Typography sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
            Enter the details for the company you want to add here
          </Typography>
          <FormControl sx={{ paddingTop: "20px" }}>
            <div>
              <MuiFileInput
                value={file}
                onChange={handleFileChange}
                label="Upload company image in a standard format, preferably square"
                sx={{ paddingRight: "20px", paddingBottom: "20px" }}
              ></MuiFileInput>
              <TextField
                id="companyField"
                value={companyName}
                onChange={handleCompanyChange}
                label="Company name"
                sx={{ paddingRight: "20px" }}
              ></TextField>
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                sx={{ height: "56px" }}
              >
                Add company
              </Button>
            </div>
          </FormControl>
          <Typography sx={{ maxWidth: "400px", margin: "0 auto" }}>
            If the show all companies tab isn't updated immediately upon adding
            a new company, refresh the page
          </Typography>
        </>
      )}
    </>
  );
}
