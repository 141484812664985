import http from "../http-common";

export const getAllUsers = async (email, page, pageSize) => {
  return new Promise(async (resolve, reject) => {
    let url;
    if (email) {
      url = `/users?email=${email}`;
    } else {
      url = `/users?page=${page}&pageSize=${pageSize}`;
    }
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    try {
      const response = await http.request(config);
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};
export const createUser = async (user) => {
  return new Promise(async (resolve, reject) => {
    const config = {
      method: "post",
      url: "/users",
      headers: {
        "Content-type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      data: {
        name: user.name,
        password: user.password,
        email: user.email,
        roles: user.roles,
      },
    };
    try {
      const response = await http.request(config);
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};

export const getUserById = async (id) => {
  return http.get(`/users/${id}`);
};

export const editUser = async (user) => {
  return new Promise(async (resolve, reject) => {
    let newUser;
    try {
      const response = await getAllUsers(user.oldEmail, "");
      console.log(response);
      newUser = response.data.users[0];
      console.log(response);
    } catch (e) {
      console.error(e);
    }
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/users/${newUser.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
      data: {
        name: user.name,
        email: user.email,
        password: user.password,
        roles: user.roles,
      },
    };
    try {
      const response = await http.request(config);
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};
export const signOut = async () => {
  //the token and auth details are stored in localstorage
  localStorage.clear();
  return http.post(`/signout`);
};
export const signIn = async (body) => {
  return new Promise(async (resolve, reject) => {
    let data = JSON.stringify({
      email: body.email,
      password: body.password,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const response = await http.request(config);
      resolve(response.data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};
