import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  CssBaseline,
  Button,
} from "@mui/material";
import "./css/certificate/certificate.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import Header from "./adminPanel/Header";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getCompanyById } from "./services/companies.service";
import certificatesService from "./services/certificates.service";
import hirebrainLogo from "./media/logo.svg";
import linkedIn from "./media/linkedIn.png";
import oneStar from "./media/one_star.svg";
import twoStar from "./media/two_star.svg";
import threeStar from "./media/three_star.svg";
import Signature from "./media/signature.jpg";
import advanced from "./media/advanced.png";
import committed from "./media/committed.png";
import certified from "./media/certified.png";
import facilitator from "./media/facilitator.png";
import cert_trainer from "./media/cert_trainer.png";
import master_trainer from "./media/master_trainer.png";
import iwanti from "./media/logo-placeholder.png";
import iwantiLogo from "./media/iwanti-logo.png";
import { PDFDownloadLink } from "@react-pdf/renderer";
import generateBasicDetailsPDF from "./utils/generateBasicDetailsPDF";
import StarIcon from "@mui/icons-material/Star";

export default function Certificate() {
  class Level {
    certificateLevels = {
     
        manager_commitment: {
          name: "one",
          count: 1,
          title: "Commitment",
          cert: committed,
          description_id: "commited_desc",
        },
        manager_certified: {
          name: "two",
          count: 2,
          title: "Certified",
          cert: certified,
          description_id: "certified_desc",
        },
        manager_advanced: {
          name: "three",
          count: 3,
          title: "Advanced",
          cert: advanced,
          description_id: "advanced_desc",
        },
     
      
        trainer_facilitator: {
          name: "one",
          count: 1,
          title: "Facilitator",
          cert: facilitator,
          description_id: "facilitator_desc",
        },
        trainer_certified: {
          name: "two",
          count:2,
          title: "Certified Trainer",
          cert: cert_trainer,
          description_id: "certified_trainer_desc",
        },
        trainer_master: {
          name: "three",
          count:3,
          title: "Master Trainer",
          cert: master_trainer,
          description_id: "master_trainer_desc",
        },
     
    };
    constructor(level) {
      this.level = this.certificateLevels[level]["count"];
      this.levelName = this.certificateLevels[level]["name"];
      this.title = this.certificateLevels[level]["title"];
      this.cert = this.certificateLevels[level]["cert"];
      this.description_id =
        this.certificateLevels[level]["description_id"];

      this.getStars = function () {
        return Array.from({ length: this.level }, (_, index) => (
          <StarIcon key={index} sx={{ position: "relative", top: "-2px" }} />
        ));
      };
    }
  }
  const [level, setLevel] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [company, setCompany] = React.useState(null);
 
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  //the ID of the certificate
  const { id } = useParams();

  const loadData = async () => {
    try {
      const response = await certificatesService.getCertById(id);
      
      setData(response.data);
      const companyResponse = await getCompanyById(response.data.companyId);
      setCompany(companyResponse.data);
     
      /*if (response.data.credential_type.includes("1")) {
        setLevel(new Level(1, "certificate"));
      } else if (response.data.credential_type.includes("2")) {
        setLevel(new Level(2, "certificate"));
      } else if (response.data.credential_type.includes("3")) {
        setLevel(new Level(3, "certificate"));
      } else if (response.data.credential_type.includes("4")) {
        setLevel(new Level(1, "trainer"));
      } else if (response.data.credential_type.includes("5")) {
        setLevel(new Level(2, "trainer"));
      } else if (response.data.credential_type.includes("6")) {
        setLevel(new Level(3, "trainer"));
      }*/
      setLevel(new Level(response.data.credential_type));
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const apiServerUrl = process.env.REACT_APP_SERVER_URL;
  console.log(apiServerUrl);

  return (
    // <ThemeProvider theme={theme}>
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Grid
          container
          id="header"
          style={{ borderBottom: "1px solid lightgrey", alignItems: "center" }}
        >
          <Grid item md={6} className="headerLeft">
            <a href="/adminpanel/certificates">
              <img
                alt="Company Logo"
                src={hirebrainLogo}
                style={{ width: "219px" }}
              ></img>
            </a>
          </Grid>
          <Grid item md={6} className="headerRight textRight pR40">
            <img
              className="imgFluid"
              alt="Company Logo 2"
              src={
                company?.logo_path &&
                company.logo_path.replace(
                  "./",
                  apiServerUrl
                )
              }
              style={{ width: "226px" }}
            ></img>
          </Grid>
        </Grid>
        {/* {data && ( */}
        <Box component="div" id="mainContent" className="container pT37">
          <Grid container className="pB20" sx={{ alignItems: "center" }}>
            <Grid item md={7}>
              <Typography className="certificateTitle" variant="h4">
                Certification Details & Verification
              </Typography>
            </Grid>
            <Grid item md={5} className="textRight">
              <Box
                className="dFlex alignItemCenter"
                sx={{ alignItems: "center", justifyContent: "end" }}
              >
                <a
                  className="dFlex linkedInImg"
                  href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=HireBrain ${
                    level != null && level.title
                  } Level&organizationId=40909267&issueYear=${
                    data != null &&
                    data.date_issued &&
                    data.date_issued.substring(0, 4)
                  }&issueMonth=${
                    data != null &&
                    data.date_issued &&
                    data.date_issued.substring(5, 7)
                  }&expirationYear=${
                    data != null &&
                    data.date_expiry &&
                    data.date_expiry.substring(0, 4)
                  }&expirationMonth=${
                    data != null &&
                    data.date_expiry &&
                    data.date_expiry.substring(5, 7)
                  }&certUrl=${apiServerUrl}/certificates/${
                    data != null && data.id && data.id
                  }&certId=${data != null && data.id && data.id}`}
                >
                  <img src={linkedIn} alt="LinkedIn Add to Profile button" />
                </a>
                {data && level && (
                  <PDFDownloadLink
                    document={generateBasicDetailsPDF(data, level, apiServerUrl, company)}
                    fileName="Certificate.pdf"
                    className="buttonSecondary mL8"
                  >
                    <i className="icon-down-arrow-line"></i> Download
                    Certificate
                  </PDFDownloadLink>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container className="pB12">
            <Grid
              item
              md={6}
              className="borderGrey textLeft p24 maxW500 certificateCard"
            >
              <Typography variant="h3">
                Completed by {data != null && data.recipient_name}
              </Typography>
              <Typography component="p">
                {data != null && data.date_issued}
              </Typography>
              <Typography component="p">
                {data != null && data.recipient_name} has successfully completed
                the requisite training and demonstrated outstanding commitment
                and proficiency as a hiring manager
              </Typography>
              <Grid
                container
                sx={{ alignItems: "center" }}
                className="certificateType p12 mB20"
              >
                <Grid item md={9} sx={{ alignItems: "center" }}>
                  <Typography component="h4">
                    {/* <i className="icon-star"></i> */}
                    {/*<img
                      alt="two star"
                      src={twoStar}
                      style={{ width: "57px" }}
                     ></img>*/}
                    HireBrain {level != null && level.title} Level
                    {level?.getStars()}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  className="textRight"
                  sx={{ alignItems: "center", justifyContent: "end" }}
                >
                  <Link onClick={() => scrollToSection(level.description_id)}>
                    View Details
                  </Link>
                </Grid>
              </Grid>
              <Box className="courseArea">
                <Typography component="h5">Course Components</Typography>
                <Typography component="p">
                  Scientific Approach towards Hiring
                </Typography>
                <Typography component="p">Understanding Opportunity</Typography>
                <Typography component="p">Understanding Role</Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              className="pL12 maxW542 certificateCard certificateArea"
            >
              <Box className="borderGrey h100 positionRelative">
                <Box className="certificateBg">
                  <img alt="" src={level?.cert} className="imgFluid"></img>
                </Box>
                <Box className="positionRelative">
                  <Box className="textRight pT8 pR17">
                    <img
                      className="imgFluid"
                      alt="Company Logo 2"
                      src={
                        company?.logo_path2 &&
                        company.logo_path2.replace(
                          "./",
                          apiServerUrl
                        )
                      }
                      style={{ width: "35px" }}
                    ></img>
                  </Box>
                  <Box className="completeTitle">
                    Certificate of{" "}
                    <Typography component="span">Completion</Typography>
                  </Box>
                  <Box className="certificateContent">
                    <Typography component="p">
                      This is to certify that
                    </Typography>
                    <Typography component="h4">
                      {data != null && data.recipient_name}
                    </Typography>
                    <Typography component="p" className="extraContent">
                      has successfully completed the requisite training and
                      demonstrated outstanding commitment and proficiency as a
                      hiring manager
                    </Typography>
                  </Box>
                  <Box className="certificateIssue textLeft">
                    <Box className="dFlex">
                      <Typography component="p">
                        Issued on:{" "}
                        <Typography component="strong">
                          {data != null && data.date_issued}{" "}
                        </Typography>
                        <Typography component="strong">|</Typography>{" "}
                      </Typography>

                      <Typography component="p">
                        Expires on:{" "}
                        <Typography component="strong">
                          {data != null && data.date_expiry}
                        </Typography>
                      </Typography>
                    </Box>
                    <Typography component="p">
                      Credential ID:
                      <Typography component="strong">
                        {data != null && data.id}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box className="signature">
                    <Box className="signatureInner">
                      <img
                        className="imgFluid w80"
                        alt="Company Logo 2"
                        src={
                          data?.signature_1 &&
                          data.signature_1.replace(
                            "./",
                            apiServerUrl
                          )
                        }
                      ></img>
                      <Box className="byName">
                        <Typography component="h5">
                          {data != null && data.signature_name_1}
                        </Typography>
                        <Typography component="p">
                          {" "}
                          {data != null && data.signature_role_1}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="signatureInner">
                      <img
                        className="imgFluid w80"
                        alt="Company Logo 2"
                        src={
                          data?.signature_2 &&
                          data.signature_2.replace(
                            "./",
                            apiServerUrl
                          )
                        }
                      ></img>
                      <Box className="byName">
                        <Typography component="h5">
                          {data != null && data.signature_name_2}
                        </Typography>
                        <Typography component="p">
                          {data != null && data.signature_role_2}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              md={12}
              className="borderGrey textLeft p24  certificateCard"
            >
              <Box className="certificateLevel pB32">
                <Typography className="pB7 certTitle" variant="h3">
                  HireBrain Certification Levels
                </Typography>
                <div id="commited_desc">
                  <Typography component="h6">
                    Level 1{/* {levels[0].getStars()} */}
                    <Typography component="span" className="level">
                      HireBrain Commitment Level
                    </Typography>
                    <Typography component="span" className="icon">
                      <img
                        alt="one star"
                        src={oneStar}
                        style={{ width: "20px" }}
                      ></img>
                    </Typography>
                  </Typography>
                  <Typography component="p">
                    This level recognizes your dedication to refining your
                    hiring practices. As a Committed Manager, you've completed
                    basic HireBrain training modules and shown promising strides
                    in incorporating best practices in your hiring process.
                    Continue on this path to unlock further professional growth
                    and make better hiring decisions!
                  </Typography>
                </div>
                <div id="certified_desc">
                  <Typography component="h6">
                    Level 2{/* {levels[1].getStars()} */}
                    <Typography component="span" className="level">
                      HireBrain Certified Level
                    </Typography>
                    <Typography component="span" className="icon">
                      <img
                        alt="two star"
                        src={twoStar}
                        style={{ width: "42px" }}
                      ></img>
                    </Typography>
                  </Typography>
                  <Typography component="p">
                    Achieving the Certified Manager level marks a significant
                    milestone in your professional development. This status is a
                    testament to your mastery of the key elements of the
                    HireBrain training. Your comprehensive understanding of our
                    proven hiring methodologies is now a key asset in your role
                    as a manager. Your continued commitment to excellence in
                    hiring is an inspiration!
                  </Typography>
                </div>
                <div id="advanced_desc">
                  <Typography component="h6">
                    Level 3{/* {levels[2].getStars()} */}
                    <Typography component="span" className="level">
                      HireBrain Advanced Level
                    </Typography>
                    <Typography component="span" className="icon">
                      <img
                        alt="three star"
                        src={threeStar}
                        style={{ width: "65px" }}
                      ></img>
                    </Typography>
                  </Typography>
                  <Typography component="p">
                    The Advanced Manager level is a true testament to your
                    exceptional capabilities and dedication to hiring
                    excellence. You've not only completed all HireBrain training
                    modules, but you've also shown a remarkable ability to apply
                    your knowledge in real-world situations, making strategic
                    hiring decisions that positively impact your organization.
                    You represent the pinnacle of professional achievement in
                    hiring management!
                  </Typography>
                </div>
              </Box>
              <Box className="certificateLevel pT32 topBorder">
                <Typography className="pB7 certTitle" variant="h3">
                  HireBrain Trainer Certification Levels
                </Typography>
                <div id="facilitator_desc">
                  <Typography component="h6">
                    Level 1{/* {levels[0].getStars()} */}
                    <Typography component="span" className="level">
                      HireBrain Facilitator Level
                    </Typography>
                    <Typography component="span" className="icon">
                      <img
                        alt="one star"
                        src={oneStar}
                        style={{ width: "20px" }}
                      ></img>
                    </Typography>
                  </Typography>
                  <Typography component="p">
                    As a HireBrain facilitator, you have demonstrated your
                    mastery of the HireBrain Hiring training and successfully
                    facilitated breakout groups with a wide range of managers
                    and leaders from many different functions. This was no easy
                    feat! Your commitment to supporting the professional growth
                    of hiring managers and the success of the company is
                    something to be proud of and applauded! You care, and it
                    shows.
                  </Typography>
                </div>
                <div id="certified_trainer_desc">
                  <Typography component="h6">
                    Level 2{/* {levels[1].getStars()} */}
                    <Typography component="span" className="level">
                      HireBrain Certified Trainer
                    </Typography>
                    <Typography component="span" className="icon">
                      <img
                        alt="two star"
                        src={twoStar}
                        style={{ width: "42px" }}
                      ></img>
                    </Typography>
                  </Typography>
                  <Typography component="p">
                    As a HireBrain facilitator, you have demonstrated your
                    mastery of the HireBrain Hiring training and successfully
                    facilitated breakout groups with a wide range of managers
                    and leaders from many different functions. This was no easy
                    feat! Your commitment to supporting the professional growth
                    of hiring managers and the success of the company is
                    something to be proud of and applauded! You care, and it
                    shows.
                  </Typography>
                </div>
                <div id="master_trainer_desc">
                  <Typography component="h6">
                    Level 3{/* {levels[2].getStars()} */}
                    <Typography component="span" className="level">
                      HireBrain Master Trainer
                    </Typography>
                    <Typography component="span" className="icon">
                      <img
                        alt="three star"
                        src={threeStar}
                        style={{ width: "65px" }}
                      ></img>
                    </Typography>
                  </Typography>
                  <Typography component="p">
                    As a HireBrain facilitator, you have demonstrated your
                    mastery of the HireBrain Hiring training and successfully
                    facilitated breakout groups with a wide range of managers
                    and leaders from many different functions. This was no easy
                    feat! Your commitment to supporting the professional growth
                    of hiring managers and the success of the company is
                    something to be proud of and applauded! You care, and it
                    shows.
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
}
