import {
  Typography,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  FormGroup,
  Checkbox,
} from "@mui/material";
import * as React from "react";
import { Navigate } from "react-router-dom";
import { addUsers as addUsersService } from "../../services/users.service";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/actions/userActions";
import { getAllUsers } from "../../services/users.service";
import { createUser } from "../../services/users.service";

export default function AddUser(props) {
  const dispatch = useDispatch();
  const [isRendered, setIsRendered] = React.useState(props.isRendered);
  const { users } = useSelector((state) => state.UserReducer);

  React.useEffect(() => {
    // setUsers(props.users);
    setIsRendered(props.isRendered);
    // setAllUsersLoaded(props.allUsersLoaded);
  }, [props.isRendered]);

  const [userName, setUserName] = React.useState("");
  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = React.useState("");
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const [isAdmin, setIsAdmin] = React.useState(false);
  const handleAdminChange = (event) => {
    setIsAdmin(event.target.checked);
  };

  const [isUser, setIsUser] = React.useState(false);
  const handleUserChange = (event) => {
    setIsUser(event.target.checked);
  };

  const handleSubmit = async (event) => {
    if (!isAdmin && !isUser) {
      return window.alert("You must give roles to the user");
    }
    if (!(userName || email || password)) {
      return window.alert("All text fields must be filled out");
    }
    //cool regex magic
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      return window.alert("You must enter a valid email address");
    }
    //case where all users have already been loaded by another function
    // if (allUsersLoaded) {
    //   users.forEach((element) => {
    //     if (element.email.toLowerCase() === email.toLowerCase()) {
    //       return window.alert("Email is already in use");
    //     }
    //   });
    // } else {
    //   //ideally we actually want a way to move set allUsersLoaded up the hierarchy to prevent multiple calls requesting the entire users API (max 2)
    //   //This works for now
    //   const response = await getAllUsers("", 1, 2147483647);
    //   // setUsers(response.data.users);
    //   response.data.users.forEach((element) => {
    //     if (element.email.toLowerCase() === email.toLowerCase()) {
    //       return window.alert("Email is already in use");
    //     }
    //   });
    // }

    const newRoles = [];
    if (isUser) newRoles.push("user");
    if (isAdmin) newRoles.push("admin");

    try {
      const response = await createUser({
        name: userName,
        email: email,
        password: password,
        roles: newRoles,
      });
      return window.alert("User added successfully");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isRendered && (
        <div style={{ minWidth: "900px" }}>
          <Typography sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
            Enter the details for the user you want to add here
          </Typography>
          <FormControl sx={{ paddingTop: "20px" }}>
            <div style={{ display: "flex", paddingBottom: "20px" }}>
              <TextField
                value={userName}
                onChange={handleNameChange}
                label="Name"
                sx={{ paddingRight: "20px" }}
              ></TextField>
              <TextField
                value={email}
                onChange={handleEmailChange}
                label="Email"
                sx={{ paddingRight: "20px" }}
              ></TextField>
              <TextField
                value={password}
                onChange={handlePasswordChange}
                type="password"
                label="Password"
                sx={{ paddingRight: "20px" }}
              ></TextField>

              <FormGroup>
                <FormControlLabel
                  sx={{ top: "100px", maxHeight: "48px" }}
                  control={
                    <Checkbox value={isUser} onChange={handleUserChange} />
                  }
                  label="User"
                />
                <FormControlLabel
                  sx={{ maxHeight: "30px" }}
                  control={
                    <Checkbox value={isAdmin} onChange={handleAdminChange} />
                  }
                  label="Admin"
                />
              </FormGroup>
            </div>
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              sx={{ height: "56px", borderTop: "20px" }}
            >
              Add user
            </Button>
          </FormControl>
        </div>
      )}
    </>
  );
}
