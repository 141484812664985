import { companyConstants } from "../constants/company.constants";

const initialState = {
  companies: [],
};

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case companyConstants.ALL_COMPANIES:
      return { ...state, companies: action.payload };

    default:
      return state;
  }
};

export default CompanyReducer;
