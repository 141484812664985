import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import HirebrainIcon from "./media/hirebrain-icon.webp";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signIn } from "./services/users.service";

const defaultTheme = createTheme();

export default function SignIn() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      email: email,
      password: password,
    };
    try {
      const response = await signIn(body);
      // console.log(response)a
      localStorage.setItem("token", response.token);
      localStorage.setItem("privileges", response.roles);
      if (response.roles.includes("ROLE_ADMIN")) {
        navigate("adminpanel/certificates");
      } else navigate("certById");
    } catch (e) {
      console.error(e);
      if (e.response.status === 404) window.alert("User not found");
    }
  };
  const [email, setEmail] = React.useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const [password, setPassword] = React.useState(null);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  React.useEffect(() => {
    if (localStorage.getItem("privileges")) {
      if (
        localStorage.getItem("privileges").includes("ROLE_ADMIN") &&
        localStorage.getItem("token")
      ) {
        navigate("adminpanel/");
      }
    }
  });
  const navigate = useNavigate();

  return (
    <>
      {" "}
      {
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1 }} src={HirebrainIcon}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  onChange={handleEmailChange}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  onChange={handlePasswordChange}
                  value={password}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      onClick={() => navigate("certById")}
                      href=""
                      variant="body2"
                    >
                      Search for certificate by ID (does not require login)
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      }
    </>
  );
}
