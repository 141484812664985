import http from "../http-common";

export const getCompanyById = async (id) => {
  return http.get(`/companies/${id}`);
};

export const getAllCompanies = async (name, page, pageSize) => {
  return new Promise(async (resolve, reject) => {
    let url;
    if (name) {
      url = `/companies?name=${name}`;
    } else {
      url = `/companies?page=${page}&pageSize=${pageSize}`;
    }
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    try {
      const response = await http.request(config);
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};
export const addCompany = async (file, name) => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/companies`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${localStorage.getItem("token")}`,
      },
      data: formData,
    };

    try {
      const response = await http.request(config);
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};
export const editCompany = async (oldName, name, file) => {
  return new Promise(async (resolve, reject) => {
    let company;
    try {
      const response = await getAllCompanies(oldName);
      console.log(response);
      company = response.data.companies[0];
    } catch (e) {
      console.error(e);
    }
    const formData = new FormData();
    if (file) formData.append("file", file);
    if (name) formData.append("name", name);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/companies/${company.id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${localStorage.getItem("token")}`,
      },
      data: formData,
    };
    try {
      const response = await http.request(config);
      resolve(response);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
};
