import "./App.css";
import "./css/style.scss";
import "./css/icons.css";
import { BrowserRouter, Link, Routes, Route, Outlet } from "react-router-dom";
import "./login.js";
import SignIn from "./login.js";
import Paperbase from "./adminPanel/Paperbase";
import CertsByCompany from "./CertsByCompany";
import CertFromId from "./CertFromId";
import Certificate from "./Certificate";
import ShowAllCompanies from "./adminPanel/companyPanel/ShowAllCompanies";
import UserManagementPanel from "./adminPanel/UserManagement";
import AddUser from "./adminPanel/userPanel/AddUser";
import EditCompany from "./adminPanel/companyPanel/EditCompany";
import EditUser from "./adminPanel/userPanel/EditUser";
import ShowAllUsers from "./adminPanel/userPanel/ShowAllUsers";
import CSVUpload from "./adminPanel/CSVUpload";
import CompanyPanel from "./adminPanel/CompanyPanel";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<SignIn />} />
        <Route path="adminpanel" element={<Paperbase />}>
          <Route path="certificates" element={<CSVUpload />}></Route>
          <Route path="companies" element={<CompanyPanel />} />
          <Route
            path="companyCertificates/:companyId"
            element={<CertsByCompany />}
          />
          <Route path="users" element={<UserManagementPanel />} />
        </Route>
        <Route exact path="/certFromId" element={<CertFromId />} />
        <Route exact path="/certById" element={<CertFromId />} />
        <Route exact path="/certificates/:id" element={<Certificate />} />
        <Route exact path="/EditCompany" element={<EditCompany />} />
        <Route exact path="/AddUser" element={<AddUser />} />
        <Route exact path="/EditUser" element={<EditUser />} />
        <Route exact path="/ShowAllUsers" element={<ShowAllUsers />} />
      </Routes>
    </div>
  );
}

export default App;
