import {
  Typography,
  FormControl,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import * as React from "react";
// import companiesService from "../../services/companies.service";
import { editCompany as editCompanyService } from "../../services/companies.service";
import { useSelector, useDispatch } from "react-redux";
import { getCompanies } from "../../redux/actions/companyActions";

export default function EditCompany(props) {
  const dispatch = useDispatch();
  const [isRendered, setIsRendered] = React.useState(props.isRendered);
  const { companies } = useSelector((state) => state.CompanyReducer);
  const [companyNames, setCompanyNames] = React.useState(["none"]);

  React.useEffect(() => {
    try {
      setIsRendered(props.isRendered);
      if (companies) setCompanyNames(companies.map((company) => company.name));
    } catch (e) {
      console.error(e);
    }
  }, [props.isRendered, companies]);

  const [file, setFile] = React.useState(null);

  const handleFileChange = (event) => {
    if (!event) {
      return setFile(event);
    }
    if (!event.type.includes("image")) {
      window.alert(
        "You chose an invalid file type. Please choose a valid image type"
      );
      return setFile(null);
    }
    const selectedFile = event;
    return setFile(selectedFile);
  };

  const [newCompanyName, setNewCompanyName] = React.useState(null);
  const handleNewCompanyChange = (event) => {
    setNewCompanyName(event.target.value);
  };

  const [company, setCompany] = React.useState(null);

  const [oldCompanyName, setOldCompanyName] = React.useState(null);
  const handleOldCompanyNameChange = (event) => {
    if (event.target.outerText) {
      setOldCompanyName(event.target.outerText);
      setCompany(
        companies.find((item) => item.name === event.target.outerText)
      );
    } else {
      setOldCompanyName("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!oldCompanyName) {
      return window.alert("Old company name field cannot be empty");
    }
    if (!newCompanyName && !file) {
      return window.alert("You must enter a field to edit");
    }
    if (newCompanyName) {
      if (
        newCompanyName !== oldCompanyName &&
        companies.find((item) => item.name === newCompanyName)
      ) {
        return window.alert("Company name is taken");
      }
    }
    const submittedName = newCompanyName || oldCompanyName;
    try {
      const response = await editCompanyService(
        oldCompanyName,
        submittedName,
        file
      );
      dispatch(getCompanies());

      return window.alert("Company edited successfully");
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      {isRendered && (
        <div style={{ minWidth: "600px" }}>
          <Typography sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
            Enter the details for the company you want to edit here
          </Typography>
          <FormControl sx={{ paddingTop: "20px" }}>
            <div>
              <Autocomplete
                sx={{
                  maxWidth: "200px",
                  position: "relative",
                  margin: "0 auto",
                }}
                options={companyNames}
                value={oldCompanyName}
                onChange={handleOldCompanyNameChange}
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Old company name"
                    sx={{ maxWidth: "200px", margin: "20px" }}
                  />
                )}
              />
              <MuiFileInput
                value={file}
                onChange={handleFileChange}
                label="Upload new company image in a standard format, preferably square"
                sx={{ paddingRight: "20px" }}
              ></MuiFileInput>
              <TextField
                value={newCompanyName}
                onChange={handleNewCompanyChange}
                label="New company name"
                sx={{ paddingRight: "20px" }}
              ></TextField>
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                sx={{ height: "56px" }}
              >
                Edit company
              </Button>
            </div>
          </FormControl>
        </div>
      )}
    </>
  );
}
