import * as React from "react";
import { Typography, Tab, AppBar, Tabs } from "@mui/material";
import AddUser from "./userPanel/AddUser";
import EditUser from "./userPanel/EditUser";
import ShowAllUsers from "./userPanel/ShowAllUsers";
import { getAllUsers } from "../services/users.service";
import { Link, Navigate, Outlet } from "react-router-dom";

function UserManagementPanel(props) {
  const [users, setUsers] = React.useState(null);
  const [isRendered, setIsRendered] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [allLoaded, setAllLoaded] = React.useState(false);

  const loadData = async (page, pageSize) => {
    try {
      const response = await getAllUsers("", page, pageSize);
      console.log(response.data.users);
      setUsers(response.data.users);
    } catch (e) {
      console.error(e);
    }
  };

  const [activeTab, setActiveTab] = React.useState("ShowAllUsers");

  const handleTabChange = (event, newTab) => {
    console.log("users:");
    console.log(users);
    if (newTab === "EditUser" && !allLoaded) {
      loadData(1, 2147483647);
      setAllLoaded(true);
    }
    return setActiveTab(newTab);
  };

  React.useEffect(() => {
    setIsRendered(props.isRendered);
    if (!users && !allLoaded) {
      loadData(page, pageSize);
    }
  }, [props.isRendered, allLoaded]);
  return (
    <>
      {
        <div>
          <AppBar
            component="div"
            position="static"
            elevation={0}
            sx={{ zIndex: 0, background: "#225599" }}
          >
            <Tabs
              textColor="inherit"
              value={activeTab}
              onChange={handleTabChange}
            >
              <Tab label="Show all users" value="ShowAllUsers" />
              <Tab label="Add a user" value="AddUser" />
              <Tab label="Modify a user" value="EditUser" />
            </Tabs>
          </AppBar>
          <ShowAllUsers
            allUsersLoaded={allLoaded}
            users={users}
            page={page}
            pageSize={pageSize}
            isRendered={activeTab === "ShowAllUsers"}
          />
          <AddUser
            allUsersLoaded={allLoaded}
            users={users}
            page={page}
            isRendered={activeTab === "AddUser"}
          />
          <EditUser
            allUsersLoaded={allLoaded}
            users={users}
            page={page}
            isRendered={activeTab === "EditUser"}
          />
        </div>
      }
    </>
  );
}

export default UserManagementPanel;
