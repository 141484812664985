import { combineReducers } from "redux";
import CompanyReducer from "./CompanyReducer";
import UserReducer from "./UserReducer";

const rootReducers = combineReducers({
  CompanyReducer,
  UserReducer,
});

export default rootReducers;
